var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "auth-wrapper auth-v2"
  }, [_c('b-row', {
    staticClass: "auth-inner m-0"
  }, [_c('b-link', {
    staticClass: "brand-logo"
  }, [_c('vuexy-logo'), _c('h2', {
    staticClass: "brand-text text-primary ml-1"
  }, [_vm._v(" Vuexy ")])], 1), _c('b-col', {
    staticClass: "d-none d-lg-flex align-items-center p-5",
    attrs: {
      "lg": "8"
    }
  }, [_c('div', {
    staticClass: "w-100 d-lg-flex align-items-center justify-content-center px-5"
  }, [_c('b-img', {
    attrs: {
      "fluid": "",
      "src": _vm.imgUrl,
      "alt": "Login V2"
    }
  })], 1)]), _c('b-col', {
    staticClass: "d-flex align-items-center auth-bg px-2 p-lg-5",
    attrs: {
      "lg": "4"
    }
  }, [_c('b-col', {
    staticClass: "px-xl-2 mx-auto",
    attrs: {
      "sm": "8",
      "md": "6",
      "lg": "12"
    }
  }, [_c('b-card-title', {
    staticClass: "font-weight-bold mb-1",
    attrs: {
      "title-tag": "h2"
    }
  }, [_vm._v(" Welcome to Vuexy! 👋 ")]), _c('b-card-text', {
    staticClass: "mb-2"
  }, [_vm._v(" Please sign-in to your account and start the adventure ")]), _c('validation-observer', {
    ref: "loginValidation"
  }, [_c('b-form', {
    staticClass: "auth-login-form mt-2",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Email",
      "label-for": "login-email"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Email",
      "rules": "required|email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "login-email",
            "state": errors.length > 0 ? false : null,
            "name": "login-email",
            "placeholder": "john@example.com"
          },
          model: {
            value: _vm.userEmail,
            callback: function callback($$v) {
              _vm.userEmail = $$v;
            },
            expression: "userEmail"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('label', {
    attrs: {
      "for": "login-password"
    }
  }, [_vm._v("Password")]), _c('b-link', {
    attrs: {
      "to": {
        name: 'auth-forgot-password-v2'
      }
    }
  }, [_c('small', [_vm._v("Forgot Password?")])])], 1), _c('validation-provider', {
    attrs: {
      "name": "Password",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-input-group', {
          staticClass: "input-group-merge",
          class: errors.length > 0 ? 'is-invalid' : null
        }, [_c('b-form-input', {
          staticClass: "form-control-merge",
          attrs: {
            "id": "login-password",
            "state": errors.length > 0 ? false : null,
            "type": _vm.passwordFieldType,
            "name": "login-password",
            "placeholder": "············"
          },
          model: {
            value: _vm.password,
            callback: function callback($$v) {
              _vm.password = $$v;
            },
            expression: "password"
          }
        }), _c('b-input-group-append', {
          attrs: {
            "is-text": ""
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": _vm.passwordToggleIcon
          },
          on: {
            "click": _vm.togglePasswordVisibility
          }
        })], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "id": "remember-me",
      "name": "checkbox-1"
    },
    model: {
      value: _vm.status,
      callback: function callback($$v) {
        _vm.status = $$v;
      },
      expression: "status"
    }
  }, [_vm._v(" Remember Me ")])], 1), _c('b-button', {
    attrs: {
      "type": "submit",
      "variant": "primary",
      "block": ""
    },
    on: {
      "click": _vm.validationForm
    }
  }, [_vm._v(" Sign in ")])], 1)], 1), _c('b-card-text', {
    staticClass: "text-center mt-2"
  }, [_c('span', [_vm._v("New on our platform? ")]), _c('b-link', {
    attrs: {
      "to": {
        name: 'page-auth-register-v2'
      }
    }
  }, [_c('span', [_vm._v(" Create an account")])])], 1), _c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_vm._v(" or ")])]), _c('div', {
    staticClass: "auth-footer-btn d-flex justify-content-center"
  }, [_c('b-button', {
    attrs: {
      "variant": "facebook",
      "href": "javascript:void(0)"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "FacebookIcon"
    }
  })], 1), _c('b-button', {
    attrs: {
      "variant": "twitter",
      "href": "javascript:void(0)"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "TwitterIcon"
    }
  })], 1), _c('b-button', {
    attrs: {
      "variant": "google",
      "href": "javascript:void(0)"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "MailIcon"
    }
  })], 1), _c('b-button', {
    attrs: {
      "variant": "github",
      "href": "javascript:void(0)"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "GithubIcon"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }